import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// Import AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Import Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.scss";

import Adsense from "vue-google-adsense/dist/Adsense.min.js";
import InArticleAdsense from "vue-google-adsense/dist/InArticleAdsense.min.js";

AOS.init({
  duration: 700,
  easing: "ease-out-quad",
  once: true,
}); // Init AOS

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(require("vue-script2"));

Vue.use(Adsense);
Vue.use(InArticleAdsense);
