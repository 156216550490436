import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
var firebaseConfig = {
  apiKey: "AIzaSyDdXiKewYZAmaojR1azcNJ5lM2rPqcJlzQ",
  authDomain: "ping-b8e90.firebaseapp.com",
  projectId: "ping-b8e90",
  storageBucket: "ping-b8e90.appspot.com",
  messagingSenderId: "983093136755",
  appId: "1:983093136755:web:c3a9d5b279ed39ce410906",
  measurementId: "G-606JKTX23D",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

const inizializeAnalytics = () => {
  firebase.analytics();
};

export { db, inizializeAnalytics };
