<template>
  <div id="app">
    <Navbar />
    <div class="content-warp">
      <router-view />
    </div>
    <cookie-law buttonText="Accetto" @accept="handleCookieAccept">
      <div slot="message">
        <h5 class="text-red">TENIAMO ALLA TUA PRIVACY</h5>
        Utilizziamo i cookie per fornire la migliore esperienza sul nostro sito
        web.
        <a
          href="https://shop.elping.it/policies/privacy-policy"
          class="Cookie--link text-red"
          >Privacy Policy</a
        >
      </div>
    </cookie-law>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import CookieLaw from "vue-cookie-law";
import { inizializeAnalytics } from "@/assets/js/firebase.js";
export default {
  components: {
    Navbar,
    Footer,
    CookieLaw,
  },
  methods: {
    handleCookieAccept() {
      inizializeAnalytics();
    },
  },
  mounted() {
    // this code listens for a mutation on the main-wrapper element and resets
    // the style attribute back to "null".
    // This is necessary because Google Adsense injects style into this main-wrapper
    // element changing its height properties. Note: This only occurs in Chrome.
    let wrapper = document.getElementById("app");
    const observer = new MutationObserver(() => {
      wrapper.style.height = "";
      wrapper.style.minHeight = "";
    });
    observer.observe(wrapper, {
      attributes: true,
      attributeFilter: ["style"],
    }); // Fix google adsens applying style
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content-warp {
  padding-bottom: 6.5rem; /* Footer height + marginTop */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.full-height-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

$footer-height-lg: 120px;

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media (max-width: 768px) {
  footer {
    min-height: 9rem;
  }
  .content-warp {
    padding-bottom: 12rem;
  }
}
@media (min-width: 768px) {
  footer {
    max-height: $footer-height-lg;
  }
  .content-warp {
    padding-bottom: calc(#{$footer-height-lg} + 20px);
  }
}
</style>
